@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  overscroll-behavior: none;
  scrollbar-width    : thin;
}

html {
  scroll-padding-top: 6rem;
}

.ant-row {
  margin-right: 0 !important;
  margin-left : 0 !important;
}

/* .ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover {
  cursor    : pointer;
  transition: all 0.3s;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.link-hover {
  transition: all 0.3s;
}

.link-hover:hover {
  color: rgba(255, 255, 255, 0.75) !important;
}

.landing-button {
  background-color: #000;
  border-color    : #fff;
  color           : #fff;
  transition      : all 0.3s;
}

.landing-button:hover {
  border-color    : #000 !important;
  background-color: #fff !important;
  color           : #000 !important;
}

.landing-button-inverse {
  background-color: #fff;
  border-color    : #000;
  color           : #000;
  transition      : all 0.3s;
  font-weight     : 600;
}

.landing-button-inverse:hover {
  border-color    : #fff !important;
  background-color: #000 !important;
  color           : #fff !important;
}

.menu-hover {
  cursor    : pointer;
  transition: all 0.3s;
}

.menu-hover:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.gradient1 {
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  background-size : 200% 200%;
  animation       : gradientAnimation 8s infinite linear;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
    filter             : hue-rotate(180deg);
  }

  100% {
    background-position: 0% 50%;
    filter             : hue-rotate(360deg);
  }
}

.dot-bg {
  background      : white;
  background-image: radial-gradient(black 1px, transparent 0);
  background-size : 40px 40px;
}

.dot-bg-dark {
  background      : #02031d;
  background-image: radial-gradient(#282637 1px, transparent 0);
  background-size : 20px 20px;
}

.gradient2 {
  background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);
}

.link {
  font-weight: 500;
  color      : #000;
  transition : color 0.3s;
}

.link:hover {
  color: blue;
}

.shadow {
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.15);
}

.rotate,
.rotate-trigger {
  transition: all 0.3s;
}

.rotate-trigger:hover .rotate {
  transition: all 0.3s;

}

.rotate-trigger:hover>.link {
  color: blue;
}

.rotate-trigger:hover>.rotate {
  color    : blue;
  transform: rotate(-180deg);
}

.button-outline {
  border          : 1px solid #4312e5;
  background-color: white;
  transition      : all 0.3s;
}

.button-outline:hover {
  background-color: #4312e5 !important;
  color           : white !important;
}

/* *** Magic starts HERE */
:root {
  --diameter: 35vmax;
}

.aurora-light {
  background: #fff;
  position  : relative;
}

.aurora {
  background: #000;
  position  : relative;
}

.aurora:before,
.aurora:after,
.aurora-light:before,
.aurora-light:after {
  content      : '';
  display      : block;
  position     : absolute;
  width        : 1px;
  height       : 1px;
  border-radius: 100%;
  opacity      : 0.18;
}

.aurora:before,
.aurora-light:before {
  box-shadow: 0 0 var(--diameter) var(--diameter) red;
  animation : hue 30s linear infinite, move1 35s linear infinite;
}

.aurora:after,
.aurora-light:after {
  box-shadow: 0 0 var(--diameter) var(--diameter) cyan;
  animation : hue 35s linear infinite, move2 45s linear infinite;
}

@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@keyframes move1 {
  0% {
    top : 0vh;
    left: 50vw;
  }

  25% {
    left: 0vw;
  }

  50% {
    top: 100vh;
  }

  75% {
    left: 100vw;
  }

  100% {
    top : 0vh;
    left: 50vw;
  }
}

@keyframes move2 {
  0% {
    top : 50vh;
    left: 100vw;
  }

  25% {
    top: 100vh;
  }

  50% {
    left: 0vw;
  }

  75% {
    top: 0vh;
  }

  100% {
    top : 50vh;
    left: 100vw;
  }
}

.dashed-line {
  width : 100%;
  height: 1px;
  background: repeating-linear-gradient(to right,
      transparent 0,
      transparent 10px,
      #000 10px,
      #000 20px);
  background-size: 200% 100%;
  animation      : flow 30s linear infinite;
}

@keyframes flow {
  100% {
    background-position: -200% 0;
  }
}

.parallax-container {
  height  : 100vh;
  overflow: hidden;
}

.parallax-item {
  height             : 100%;
  background-image   : url("https://tcw-images.s3.us-west-2.amazonaws.com/email.png");
  background-size    : cover;
  background-position: center;
}

.parallax-content {
  padding         : 50px 0;
  background-color: #f0f0f0;
}

.card {
  width           : 300px;
  height          : 200px;
  background-color: #fff;
  border-radius   : 10px;
  box-shadow      : 0 4px 8px rgba(0, 0, 0, 0.1);
  transition      : transform 0.3s ease;
}

.card-content {
  padding: 20px;
}

.tilted {
  transform: rotateX(5deg) rotateY(5deg);
}

.segmented {
  transition: all 0.3s cubic-bezier(.4, 0, .2, 1);
}

.segmented:hover {
  background-color: #334155;
}

.ant-modal-mask {
  backdrop-filter: blur(8px);
}

.bg-img {
  background-image   : url('https://tcw-images.s3.us-west-2.amazonaws.com/background.jpg');
  background-size    : cover;
  background-position: center;
}

.bg-light-img {
  background-image   : url('https://tcw-images.s3.us-west-2.amazonaws.com/light-bg.jpg');
  background-size    : cover;
  background-position: center;
}

.bg-about {
  background-image   : url('https://tcw-images.s3.us-west-2.amazonaws.com/about.png');
  background-size    : contain;
  background-color   : #4312e5;
  background-position: center;
  background-repeat  : no-repeat;
}

@media (max-width: 768px) {
  .bg-about {
    background-position: top;
  }
}

.scroller {
  width: 100%;
}

.scroller__inner {
  padding-block: 1rem;
  display      : flex;
  flex-wrap    : wrap;
  gap          : 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 20%,
      white 80%,
      transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width    : max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="little-fast"] {
  --_animation-duration: 30s;
}

.scroller[data-speed="medium"] {
  --_animation-duration: 40s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.tag-list {
  margin        : 0;
  padding-inline: 0;
  list-style    : none;
}

.tag-list li {
  width        : 200px;
  padding      : 1rem;
  border-radius: 0.5rem;
}

.page-section {
  z-index        : 1;
  justify-content: center;
  align-items    : center;
  padding-top    : 60px;
  padding-bottom : 60px;
  display        : flex;
  position       : relative;
}

.container {
  width    : 95%;
  max-width: 90rem;
  position : relative;
}

.container._2 {
  border-radius: 45px;
  padding      : 135px 49px;
  overflow     : hidden;
  height       : 80vh;
}

.image {
  z-index        : -1;
  object-fit     : contain;
  object-position: 100% 0%;
  width          : 100%;
  height         : 100%;
  position       : absolute;
  top            : 0%;
  bottom         : 0%;
  left           : auto;
  right          : 0%;
}

.image._2 {
  opacity: .55;
  filter : contrast(114%) saturate(131%);
  width  : 100%;
  height : 100%;
}

.w-embed:before,
.w-embed:after {
  content  : " ";
  grid-area: 1 / 1 / 2 / 2;
  display  : table;
}

.w-embed:after {
  clear: both;
}

.video {
  object-fit     : cover;
  object-position: 50% 50%;
  width          : 100%;
  height         : 100%;
}

@media (max-width: 768px) {
  .large-heading {
    font-size: 8vw !important;
  }
}

.large-heading {
  letter-spacing         : 1px;
  -webkit-text-fill-color: transparent;
  background-image       : linear-gradient(157deg, #e8dfff, #4873ea);
  -webkit-background-clip: text;
  background-clip        : text;
  padding-bottom         : 24px;
  padding-right          : 6px;
  font-size              : 80px;
  line-height            : .8;
  font-weight            : 500;
}

.hero-paragraph {
  transform-origin: 0 100%;
  color           : #e0dbdb;
  max-width       : 50ch;
  margin-bottom   : 0;
  font-size       : 22px;
  line-height     : 1.6;
}

.hero-paragraph._2 {
  margin-top   : 20px;
  margin-bottom: 20px;
}

.foot-placeholder::placeholder {
  color: rgb(141 140 149 / 1);
}

.ant-select-selection-placeholder {
  color: rgb(141 140 149 / 1) !important;
}

.ant-select-selection-item {
  color: white !important;
}

.bg-gradient {
  background-image: linear-gradient(379deg, rgb(244, 216, 255) 30.90456644144144%, rgb(131, 104, 242) 100%);
}

.text-gradient {
  background-clip : text;
  color           : transparent;
  background-image: linear-gradient(379deg, rgb(244, 216, 255) 30.90456644144144%, rgb(131, 104, 242) 100%);
}

.glass {
  box-shadow: rgba(164, 143, 255, 0.12) 0px -7px 120px 0px inset;
}

.glass-bar {
  border          : 1px solid rgba(206, 206, 251, 0.06);
  background-color: rgba(206, 206, 251, 0.04);
  backdrop-filter : blur(16px);
  border-radius   : 12px;
}

#tsparticles {
  position: absolute;
  top     : 0;
  left    : 0;
  bottom  : 0;
  right   : 0;
  padding : 0;
  margin  : 0;
  z-index : 0;
}

.lighting-bg {
  background-image: url("https://utfs.io/f/74664282-2611-4a7d-ac80-60fa392c04fa-gelczw.png");
}

.lighting-bg-2 {
  background-image: url("https://utfs.io/f/272de90c-e34e-4dc2-a136-cccb841de6c3-2tjcxn.png");
}

.text-description {
  color: rgba(203, 203, 232, .75);
}

.glass-card {
  border                 : 1px solid rgba(206, 206, 251, 0.05);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter        : blur(16px);
  border-radius          : 12px;
  box-shadow             : inset 0px -12px 40px 0px rgba(206, 206, 251, 0.05);
  padding                : 20px 24px;
}

.team-layer-1 {
  border          : 1px solid rgba(206, 206, 251, 0.1);
  background-color: rgba(206, 206, 251, 0.02);
  width           : 100%;
  border-radius   : 20px;
  padding         : 8px;
}

.team-layer-2 {
  border       : 1px solid rgba(206, 206, 251, 0.12);
  border-radius: 12px;
  box-shadow   : rgba(206, 206, 251, 0.1) 0px 0px 20px 0px inset;
}

.nav-link {
  border          : 1px solid rgba(203, 203, 232, 0);
  background-color: rgba(203, 203, 232, 0);
  border-radius   : 6px;
  padding         : 6px 12px;
  transition      : all 0.3s;
}

.nav-link:hover {
  border          : 1px solid rgba(203, 203, 232, 0.06) !important;
  background-color: rgba(203, 203, 232, 0.04) !important;
  color           : white !important;
}